#right-align {
  text-align: right;
}

#container {
  width: 400px;
}
#floated {
  float: left;
  width: 150px;
}

#username {
  font-size: 1.5em;
  font-weight: bold;
  color: blue;
}
